@if (user$ | async; as user) {
  <div class="flex flex-row gap-2 items-center">
    @if (user?.profileImage) {
      <div class="avatar">
        <div class="w-10 rounded-full">
          <img [src]="user.profileImage" />
        </div>
      </div>
    }
    @if (!user?.profileImage) {
      <div
        class="w-10 h-10 flex items-center justify-center shrink-0"
        >
        <fa-icon [icon]="faUserTie"></fa-icon>
      </div>
    }
    @if (user.name) {
      <div>
        <p>{{ user.name }}</p>
      </div>
    }
    @if (!user.name) {
      <div>
        <p>{{ user.firstName }} {{ user.lastName }}</p>
      </div>
    }
  </div>
}

@if (name) {
  <div class="flex flex-row gap-2 items-center">
    <div class="w-10 h-10 flex items-center justify-center shrink-0">
      <fa-icon [icon]="faUserTie"></fa-icon>
    </div>
    <div>
      <p>{{ name }}</p>
    </div>
  </div>
}
