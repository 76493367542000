import { AsyncPipe } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faUserTie } from '@fortawesome/pro-regular-svg-icons';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { BaseUser } from 'src/app/shared/models';
import { selectPermissionsGrantedByUserId } from 'src/app/shared/store/permission-granted/+state/permission-granted.selectors';

@Component({
  selector: 'app-agency-tag',
  standalone: true,
  imports: [AsyncPipe, FaIconComponent],
  templateUrl: './agency-tag.component.html',
  styleUrl: './agency-tag.component.scss',
})
export class AgencyTagComponent {
  private store = inject(Store);

  @Input() agencyId?: string;
  @Input() name?: string;

  faUserTie = faUserTie;

  user$?: Observable<BaseUser | undefined>;

  ngOnInit() {
    if (this.agencyId && !this.name) {
      this.user$ = this.store
        .select(selectPermissionsGrantedByUserId(this.agencyId))
        .pipe(map(p => p.permissionGranted?.user));
    }
  }
}
